<!--
 * @Author: John
 * @Date: 2022-02-08 10:55:17
 * @LastEditTime: 2022-04-28 14:47:41
 * @LastEditors: John
 * @Description: 主页
 * @FilePath: \jjms_ui\src\views\main.vue
 * @Copyright John
-->
<template>
  <el-container class="index-con">
    <el-aside :class="showclass">
      <leftnav :collapsed="collapsed" />
    </el-aside>
    <el-container class="main-con">
      <el-header class="index-header">
        <navcon :collapsed="collapsed" @toggle="toggleHandler"></navcon>
      </el-header>
      <el-main clss="index-main">
        <router-view></router-view>
      </el-main>
      <el-footer>
        <footer-bar />
      </el-footer>
    </el-container>
  </el-container>
</template>
<style>
.index-con {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

.aside {
  width: 64px !important;
  height: 100%;
  background-color: #334157;
  margin: 0px;
}
.asideshow {
  width: 240px !important;
  height: 100%;
  background-color: #334157;
  margin: 0px;
}
.index-header,
.index-main {
  padding: 0px;
  border-left: 2px solid #333;
}
</style>
<script>
import leftnav from "../components/leftnav.vue";
import navcon from "../components/navcon.vue";
import footerBar from '../components/footer-bar.vue'
export default {
  name: "index",
  data() {
    return {
      showclass: "asideshow",
      showtype: false,
      collapsed: false,
    };
  },
  // 注册组件
  components: {
    navcon,
    leftnav,
    footerBar
  },
  methods: {
    toggleHandler(value) {
      this.collapsed = value;
      if (!value) {
        this.showclass = "asideshow";
      } else {
        setTimeout(() => {
          this.showclass = "aside";
        }, 300);
      }
    },
  },
  created() {
    // 监听
    // this.$root.Bus.$on('toggle', value => {
    // if (value) {
    //     this.showclass = 'asideshow'
    // } else {
    //     setTimeout(() => {
    //     this.showclass = 'aside'
    //     }, 300)
    // }
    // })
  },
  beforeUpdate() {},
  // 挂载前状态(里面是操作)
  beforeMount() {},
};
</script>
