/**
* 左边菜单
*/ 
<template>
  <el-menu :default-active="defaultActive" :collapse="collapsed" collapse-transition router unique-opened class="el-menu-vertical-demo" background-color="#334157" text-color="#fff" active-text-color="#ffd04b">
    <div class="logobox">
      <img class="logoimg" src="../assets/img/title_logo.png" style="height:100%;" alt="">
      <!-- <h1>{{title}}</h1> -->
    </div>
    <el-submenu v-for="menu in allmenu" :key="menu.menuid" :index="'' + menu.menuname">
      <template slot="title">
        <i :class="menu.icon"></i>
        <span>{{menu.menuname}}</span>
      </template>
      <el-menu-item-group>
        <el-menu-item v-for="chmenu in menu.menus" :index="chmenu.url" :key="chmenu.menuid">
          <i :class="chmenu.icon"></i>
          <span>{{chmenu.menuname}}</span>
        </el-menu-item>
      </el-menu-item-group>
    </el-submenu>
  </el-menu>
</template>
<script>
export default {
  name: 'leftnav',
  props: {
    collapsed: {
      type: Boolean
    }
  },
  data() {
    return {
      allmenu: [],
      defaultActive: ''
    }
  },
  // 创建完毕状态(里面是操作)
  created() {
    // 构建菜单数据（本地写死）
    let res = {
      success: true,
      data: [
        {
          menuid: 1,
          icon: 'el-icon-s-home',
          menuname: '主页',
          hasThird: null,
          url: null,
          menus: [
            {
              menuid: 11,
              // icon: 'icon-cat-skuQuery',
              icon: 'el-icon-house',
              menuname: '欢迎页',
              hasThird: 'N',
              url: 'welcome',
              menus: null
            },
          ]
        },
        {
          menuid: 2,
          icon: 'el-icon-first-aid-kit',
          menuname: '定投管理',
          hasThird: null,
          url: null,
          menus: [
            {
              menuid: 21,
              // icon: 'icon-cat-skuQuery',
              icon: 'el-icon-s-cooperation',
              menuname: '当前定投',
              hasThird: 'N',
              url: 'dtgl-list',
              menus: null
            },
            {
              menuid: 22,
              icon: 'el-icon-circle-plus',
              menuname: '添加定投',
              hasThird: 'N',
              url: 'dtgl-tjdt',
              menus: null
            }
          ]
        },
        {
          menuid: 4,
          icon: 'el-icon-coin',
          menuname: '收益管理',
          hasThird: null,
          url: null,
          menus: [
            {
              menuid: 41,
              icon: 'el-icon-money',
              menuname: '收益明细',
              hasThird: 'N',
              url: 'dtsy-list',
              menus: null
            }
          ]
        },
        {
          menuid: 6,
          icon: 'el-icon-s-order',
          menuname: '交易管理',
          hasThird: null,
          url: null,
          menus: [
            {
              menuid: 61,
              icon: 'el-icon-notebook-2',
              menuname: '交易记录查询',
              hasThird: 'N',
              url: 'trans-list',
              menus: null
            },
            {
              menuid: 62,
              icon: 'el-icon-document-add',
              menuname: '添加交易记录',
              hasThird: 'N',
              url: 'trans-add',
              menus: null
            }
          ]
        },
        {
          menuid: 5,
          icon: 'el-icon-s-data',
          menuname: '统计数据',
          hasThird: null,
          url: null,
          menus: [
            {
              menuid: 51,
              icon: 'el-icon-s-data',
              menuname: '净值数据',
              hasThird: 'N',
              url: 'networth-list',
              menus: null
            },
            {
              menuid: 52,
              icon: 'el-icon-s-marketing',
              menuname: '收益分析',
              hasThird: 'N',
              url: 'dtsy-statistic',
              menus: null
            }
          ]
        },
        {
          menuid: 3,
          icon: 'el-icon-setting',
          menuname: '功能设置',
          hasThird: null,
          url: null,
          menus: [
            {
              menuid: 31,
              icon: 'el-icon-message-solid',
              menuname: '提醒邮箱',
              hasThird: 'N',
              url: 'func-add-remind',
              menus: null
            },
            {
              menuid: 32,
              icon: 'el-icon-user-solid',
              menuname: '我的资料',
              hasThird: 'N',
              url: 'func-profile',
              menus: null
            },
            {
              menuid: 33,
              icon: 'el-icon-lock',
              menuname: '修改密码',
              hasThird: 'N',
              url: 'func-reset-pwd',
              menus: null
            }
          ]
        },
      ],
      msg: 'success'
    }
    this.allmenu = res.data

    // console.log(this.$route.path);
    let currentPath = this.$route.path;
    this.defaultActive = currentPath.slice(currentPath.lastIndexOf('/') + 1);
  },
  computed: {
    title() {
      return this.collapsed ? this.$root.appName.substring(2,4) : this.$root.appName;
    }
  }
}
</script>
<style>
.logobox > h1 {
  margin: 0;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 240px;
  min-height: 400px;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  border: none;
  text-align: left;
}
.el-menu-item-group__title {
  padding: 0px;
}
.el-menu-bg {
  background-color: #1f2d3d !important;
}
.el-menu {
  border: none;
}
.logobox {
  height: 60px;
  line-height: 60px;
  color: #9d9d9d;
  font-size: 20px;
  text-align: center;
  padding: 20px 0px;
}
.logoimg {
  height: 40px;
}
</style>