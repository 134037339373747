/*
 * @Author: John
 * @Date: 2022-02-22 15:45:39
 * @LastEditTime: 2022-03-08 18:06:58
 * @LastEditors: John
 * @Description: 
 * @FilePath: \jjms_ui\src\store\index.js
 * @Copyright John
 */
import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate"   // vuex持久化插件，解决浏览器刷新vuex中数据丢失问题

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    hasLogin: false,
    user: ''
  },
  mutations: {
    login: (state, user) => {
      // console.log(state.hasLogin)
      state.hasLogin = true;
      // localStorage.setItem("hasLogin", state.hasLogin);  // 改用vuex-persistedstate插件解决持久化问题
      // console.log('登录成功: ' + state.hasLogin);
      if(user) {
        // console.log(user)
        state.user = user;
        if(user.remember) {
          localStorage.setItem("user", JSON.stringify(user));
        }
      }
    },
    logout: (state) => {
      state.hasLogin = false;
      // localStorage.removeItem("hasLogin");  // 改用vuex-persistedstate插件解决持久化问题
      state.user = null;
    },
    modify: (state, user) => {
      if(user) {
        if(state.user && state.user.remember) {
          localStorage.setItem("user", JSON.stringify(user));  // 更新本地数据
        }
        state.user = user;  // 更新vuex中数据
      }
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [
    // createPersistedState()  // 使用默认存储位置
    createPersistedState({
      storage: window.localStorage   // 选择数据存储的位置，可以是localStorage/sessionStorage/cookie
    })
  ]
})

export default store
