<!--
 * @Author: John
 * @Date: 2022-02-05 12:59:43
 * @LastEditTime: 2022-05-07 20:06:37
 * @LastEditors: John
 * @Description: 
 * @FilePath: \jjms_ui\src\views\login.vue
 * @Copyright John
-->
<template>
    <div id="wrap">
        <div class="logo-box">
            <img src="@/assets/img/title_logo2.png" alt="">
        </div>
        <el-card class="box-card login-panel">
            <div slot="header" class="clearfix">
                <h2 style="text-align: center">欢迎登录{{$root.appName}}</h2>
            </div>
            <div v-if="loginType == 'normal'">
                <el-row :span="24">
                    <el-form ref="loginForm" :model="formData" :rules="formRule" label-width="80px">
                        <el-form-item label="用户名：" prop="username">
                            <el-input id="username" v-model="formData.username" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="密码：" prop="password">
                            <el-input id="password" v-model="formData.password" clearable show-password @blur="isAutoFill = false"></el-input>
                        </el-form-item>
                        <el-form-item prop="rememberMe">
                            <el-checkbox v-model="formData.rememberMe">记住密码</el-checkbox>
                            <router-link to="toResetPwd" style="margin-left: 30px;">忘记密码？</router-link>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="onSubmit('loginForm')">登录</el-button>
                            <span class="register">没有账号？<router-link to="/register">点此</router-link>注册</span>
                        </el-form-item>
                    </el-form>
                </el-row>
                <el-row :span="24">
                    <el-divider content-position="center">其他登录方式</el-divider>
                </el-row>
                <el-row>
                    <el-col :span="6" :offset="11">
                        <img @click="tips('腾讯要收每年300的保护费，太黑了，小破站开不起。。。')" src="@/assets/img/wx_logo.png" style="height:30px;" alt="微信扫码登录" title="微信扫码登录" />
                        <!-- <img @click="loginType = 'wx'" src="@/assets/img/wx_logo.png" style="height:30px;" alt="微信扫码登录" title="微信扫码登录" /> -->
                        <!-- <el-link type="success" @click="loginType = 'wx'">微信扫码登录</el-link> -->
                    </el-col>
                </el-row>
            </div>
            <div v-if="loginType == 'wx'">
                <el-row :span="20" :gutter="20">
                        <div id="wxlogin_container"></div>
                </el-row>
                <el-row>
                    <el-col :span="6" :offset="10">
                        <el-link @click="loginType = 'normal'">回到账号登录</el-link>
                    </el-col>
                </el-row>
            </div>

        </el-card>
        <div class="footer-bar">
            <footer-bar />
        </div>
    </div>
</template>
<script>
import api from '../api/req_api'
import sha256 from 'crypto-js/sha256'
import footerBar from '../components/footer-bar.vue'
export default {
    components: {
        footerBar
    },
    data(){
        return {
            formData: {
                username: '',
                password: '',
                rememberMe: false
            },
            formRule: {
                username: [
                    {required: true, message: '请输入用户名!', trigger: 'blur'}
                ],
                password: [
                    {required: true, message: '请输入密码!', trigger: 'blur'}
                ]
            },
            isAutoFill: false,
            loginType: 'normal'  // 登录方式：normal/wx
        };
    },
    methods: {
        onSubmit(formName){
            this.$refs[formName].validate((valid) => {
                if(valid) {
                    if(!this.isAutoFill) {
                        this.formData.password = sha256(this.formData.password).toString();    // 对密码加密
                    }
                    api.login(this.formData)
                        .then(data => {
                            if(data != null) {
                                this.$message({message: "登录成功!", type: 'success'});
                                data.remember = this.formData.rememberMe;
                                this.$store.commit('login', data);
                                // location.href = '/main';   // 使用这种方式会造成浏览器刷新，vuex中的数据会丢失
                                this.$router.push('/main');
                            } 
                        })
                } else {
                    console.log('validate failed!');
                    return false;
                }
            });
        },
        tips(msg){
            this.$message({message: msg, type: 'warning'});
        }
    },
    created() {
        let user = localStorage.getItem("user")
        if(user) {
            let userObj = JSON.parse(user)
            this.formData.username = userObj.username;
            this.formData.password = userObj.password;
            this.isAutoFill = true;
        }
    },
    watch : {
        loginType(newVal){ 
            if(newVal == 'wx') {
                console.log('初始化微信登录二维码');
                setTimeout(function(){
                    /* eslint-disable */
                    var obj = new WxLogin({
                        self_redirect:false,
                        id:"wxlogin_container", 
                        appid: "", 
                        scope: "snsapi_login", 
                        redirect_uri: "https://jj.90e2j.top",
                        state: "",
                        style: "",
                        href: ""
                    });
                }, 1000);
            }
        } 
    }
}
</script>