/*
 * @Author: John
 * @Date: 2022-02-25 11:17:49
<<<<<<< HEAD
 * @LastEditTime: 2024-01-18 10:36:23
=======
 * @LastEditTime: 2024-01-08 15:41:31
 * @LastEditors: John Li john.li@xiazhi.co
 * @Description: 
 * @FilePath: \jjms_ui\src\api\req_util.js
 * @Copyright John
 */
import axios from 'axios'
import {Message} from 'element-ui'

// 全局设置
const instance = axios.create({
    // baseURL: 'http://localhost:8983',   // 开发环境
    baseURL: 'https://jj.52fc.top/api',   // 生产环境
    // baseURL: 'http://101.200.40.123:80/api',   // 生产环境
    // baseURL: 'http://www.jj.com/api',   // 本地模拟生产环境
    headers: {"Accept": "application/json"},
    withCredentials: true
})

// 封装get方法，直接返回业务数据
const get = async function (url){
    return instance.get(url)
        .then(resp => resp.data)
        .then(data => {
            console.log('resp data: ');
            console.log(data);
            if(data.code === 403) {
                // 会话失效
                if(data.message) {
                    Message.error(data.message);
                } else {
                    Message.warning("当前会话已失效，请重新登录！");
                }
                
                setTimeout(() => {
                    location = "/login";
                }, 1000);

            } else if(data.code === 200) {
                return data.data;
            } else {
                Message.warning(`请求出错：${data.message}`);
                return null;
            }
        })
        .catch(e => {
            Message.error(`请求服务器异常：${e}`)
        })
}

// 封装post方法，直接返回业务数据
const post = (url, datas) => {
    return instance.post(url, datas)
        .then(resp => resp.data)
        .then(data => {
            console.log('resp data: ');
            console.log(data);
            if(data.code === 403) {
                // 会话失效
                if(data.message) {
                    Message.error(data.message);
                } else {
                    Message.warning("当前会话已失效，请重新登录！");
                }
                setTimeout(() => {
                    location = "/login";
                }, 1000);
            } else if(data.code === 200) {
                return data.data;
            } else {
                Message.warning(`请求出错：${data.message}`);
                return null;
            }
        })
        .catch(e => {
            Message.error(`请求服务器异常：${e}`)
        })
}

// 用表单方式提交post
const postWithForm = (url ,datas) => {
    const params = new URLSearchParams(datas);
    return instance.post(url, params)
        .then(resp => resp.data)
        .then(data => {
            console.log('resp data: ');
            console.log(data);
            if(data.code === 403) {
                // 会话失效
                if(data.message) {
                    Message.error(data.message);
                } else {
                    Message.warning("当前会话已失效，请重新登录！");
                }
                
                setTimeout(() => {
                    location = "/login";
                }, 1000);

            } else if(data.code === 200) {
                return data.data;
            } else {
                Message.warning(`请求出错：${data.message}`);
                return null;
            }
        })
        .catch(e => {
            Message.error(`请求服务器异常：${e}`)
        });
}

export default {
    get,
    post,
    postWithForm,
    instance
}
