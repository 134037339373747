/*
 * @Author: John
 * @Date: 2022-02-21 17:33:57
 * @LastEditTime: 2022-05-07 19:05:54
 * @LastEditors: John
 * @Description: 请求后台接口
 * @FilePath: \jjms_ui\src\api\req_api.js
 * @Copyright John
 */
import req from './req_util';


// 用户登录
function login(data) {
    return req.post('/user/login',data)
}

// 退出登录
function logout() {
    return req.get('/user/logout')
}

// 用户注册
function register(data) {
    return req.post('/user/register', data);
}

// 获取当前用户信息
function getUserInfo() {
    return req.get('/user/get');
}

// 更新用户信息
function updateUserInfo(data) {
    return req.post('/user/update', data);
}

// 查询定投基金列表
function queryDtList() {
    return req.get('/dt/list');
}

// 添加定投
function addDt(data) {
    return req.post('/dt/add', data);
}

// 更新定投
function updateDt(data) {
    return req.post('/dt/update', data);
}

// 按照基金名称模糊查找基金
function queryJJByName(keywords) {
    return req.get(`/jj/searchByName?keywords=${encodeURIComponent(keywords)}`);
}

// 按照基金代码查找基金
function queryJJByCode(code) {
    return req.get(`/jj/queryByCode?code=${code}`);
}

// 查询个人定投收益表
function queryDtSyList(startDate, endDate, jjCode) {
    if(!startDate) {
        startDate = '';
    }
    if(!endDate) {
        endDate = '';
    }
    if(!jjCode) {
        jjCode = '';
    }
    return req.get(`/sy/list?startDate=${startDate}&endDate=${endDate}&jjCode=${jjCode}`);
}

// 查询收益统计数据
function queryDtSyStatistic(type, jjCode) {
    if(!jjCode) {
        jjCode = '';
    }
    return req.get(`/sy/statistic?type=${type}&jjCode=${jjCode}`);
}

// 查询净值数据
function queryJzList(startDate, endDate, jjCode) {
    if(!startDate || !endDate) {
        return req.get(`/jz/list?jjCode=${jjCode}`);
    }
    return req.get(`/jz/list?jjCode=${jjCode}&startDate=${startDate}&endDate=${endDate}`);
}

// 更新收益记录
function updateDtsy(data) {
    return req.post('/sy/update', data);
}

// 查询交易记录
function queryTransList(startDate, endDate, jjCode) {
    if(!startDate) {
        startDate = '';
    }
    if(!endDate) {
        endDate = '';
    }
    if(!jjCode) {
        jjCode = '';
    }
    return req.get(`/trans/list?startDate=${startDate}&endDate=${endDate}&jjCode=${jjCode}`);
}
// 保存交易记录
function saveTrans(data) {
    return req.post('/trans/save', data);
}

// 发送重置密码邮件
function sendResetPwdEmail(email) {
    return req.get('/user/req_reset_pwd?email='+email);
}

// 重置密码
function resetPwd(data) {
    return req.postWithForm('/user/reset_pwd', data);
}

// 修改密码
function modifyPwd(data) {
    return req.postWithForm('/user/modify_pwd', data);
}

export default {
    login,
    logout,
    register,
    queryDtList,
    addDt,
    updateDt,
    queryJJByName,
    queryDtSyList,
    updateDtsy,
    getUserInfo,
    updateUserInfo,
    queryJJByCode,
    queryJzList,
    queryDtSyStatistic,
    queryTransList,
    saveTrans,
    sendResetPwdEmail,
    resetPwd,
    modifyPwd
}