/*
 * @Author: John
 * @Date: 2022-01-28 10:37:10
 * @LastEditTime: 2022-04-29 16:00:54
 * @LastEditors: John
 * @Description: 
 * @FilePath: \jjms_ui\src\main.js
 * @Copyright John
 */
import Vue from 'vue'

// 引入自定义全局样式
import '../public/css/global.css'

// 引入ElementUI
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import App from './App.vue'

import router from './router/router.js'
import store from './store'

Vue.config.productionTip = false
Vue.use(ElementUI);

new Vue({
 data: {
    appName: '小风智投'
 },
 router,
 store,
 render: h => h(App)
}).$mount('#app')
