<!--
 * @Author: John
 * @Date: 2022-04-28 14:44:22
 * @LastEditTime: 2022-12-15
 * @LastEditors: Please set LastEditors
 * @Description: 网站页脚
 * @FilePath: \jjms_ui\src\components\footer-bar.vue
 * @Copyright John
-->
<template>
    <div class="site-footer">
            <p class="linkbtn-bar">
                <a href="/about" target="_blank">关于本站</a>
                |
                <a href="/about" target="_blank">联系我
                </a> |
                <a href="/about" target="_blank">免责声明</a> |
                <a href="/help" target="_blank">帮助</a>
            </p>
            <div class="copyright-bar">
                <span>© CopyRight 2022-2025, jj.90e2j.top, Inc.All Rights Reserved.</span>
                <span><a href="https://beian.miit.gov.cn" target="_blank">豫ICP备2022028249号</a></span>
            </div>
        </div>
</template>
<style scoped>
.site-footer {
    text-align: center;
    color: #999;
    font-size: 12px;
    line-height: 12px;
    user-select: none;
}
.site-footer > .linkbtn-bar > a {
    color: #999999;
    display: inline-block;
    padding: 0 10px;
    text-decoration: none;
}
.site-footer > .copyright-bar > span,.site-footer > .copyright-bar > span > a {
    display: inline-block;
    padding-right: 10px;
    color: #c0c1c4;
    text-decoration: none;
}
</style>
<script>
export default {
    name: 'footerBar'
}
</script>
