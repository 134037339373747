<!--
 * @Author: John
 * @Date: 2022-02-08 17:22:29
 * @LastEditTime: 2022-03-08 17:48:41
 * @LastEditors: John
 * @Description: 
 * @FilePath: \jjms_ui\src\components\navcon.vue
 * @Copyright John
-->
/**
* 头部菜单
*/ 
<template>
  <el-menu class="el-menu-demo" mode="horizontal" background-color="#334157" text-color="#fff" active-text-color="#fff">
    <el-button class="buttonimg">
      <img class="showimg" :src="showImgSrc" @click="toggle()">
    </el-button>
    <el-submenu index="2" class="submenu">
      <template slot="title">{{nickname}}</template>
      <el-menu-item @click="toProfile()" index="2-2">个人中心</el-menu-item>
      <el-menu-item @click="exit()" index="2-3">退出</el-menu-item>
    </el-submenu>
  </el-menu>
</template>
<script>
import api from '../api/req_api'
export default {
  name: 'navcon',
  props: {
      collapsed: {
        type: Boolean
      }
  },
  data() {
    return {
      imgshow: require('../assets/img/show.png'),
      imgsq: require('../assets/img/sq.png'),
    }
  },
  computed: {
    showImgSrc() {
      return this.collapsed ? this.imgshow : this.imgsq;
    },
    nickname() {
      return this.$root.$store.state.user.nickname;
    }
  },
  methods: {
    // 退出登录
    exit() {
      this.$confirm('退出登录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      .then(() => {
          api.logout()
            .then(data => {
              if(data !== 200) {
                this.$store.commit('logout')
                this.$router.push({ path: '/login' })
                this.$message({
                  type: 'success',
                  message: '已退出登录!'
                })
              }
            })
        })
    },
    toProfile(){
      this.$router.push("/main/func-profile");
    },
    // 切换显示
    toggle() {
      this.$emit('toggle', !this.collapsed)
    }
  }
}
</script>
<style scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  border: none;
}
.submenu {
  float: right;
}
.buttonimg {
  height: 60px;
  background-color: transparent;
  border: none;
}
.showimg {
  width: 26px;
  height: 26px;
  position: absolute;
  top: 17px;
  left: 17px;
}
.showimg:active {
  border: none;
}
</style>