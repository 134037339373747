<!--
 * @Author: John
 * @Date: 2022-04-28 15:29:30
 * @LastEditTime: 2022-04-28 15:54:51
 * @LastEditors: John
 * @Description: 站点页面
 * @FilePath: \jjms_ui\src\views\site.vue
 * @Copyright John
-->
<template>
    <el-container class="main-con">
      <el-header class="index-header">
        <h1>{{title}}</h1>
      </el-header>
      <el-main clss="index-main">
        <router-view></router-view>
      </el-main>
      <el-footer>
        <footer-bar />
      </el-footer>
    </el-container>
</template>
<style scoped>
.main-con {
    height: 100%;
}
.index-header {
    background-color: rgb(51, 65, 87);
}
.index-header > h1 {
    height: 60px;
    line-height: 60px;
    color: #9d9d9d;
    font-size: 20px;
    text-align: center;
    margin: 0;
    padding: 0 10px;
}
</style>
<script>
import footerBar from '../components/footer-bar.vue'
export default {
    name: 'site',
    data() {
        return {
            title: this.$root.appName
        }
    },
    components: {
        footerBar
    },
    created() {
        let path = this.$route.path;
        if(path) {
            path = path.slice(path.lastIndexOf('/')+1);
        }
        console.log('path: ' + path)
        if(path == 'help') {
            this.title = this.$root.appName+'帮助中心';
        } else if(path == 'about') {
            this.title = '关于' + this.$root.appName;
        }
    }
}
</script>