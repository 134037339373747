/*
 * @Author: John
 * @Date: 2022-02-07 17:41:23
 * @LastEditTime: 2022-05-10 10:58:30
 * @LastEditors: John
 * @Description: 
 * @FilePath: \jjms_ui\src\router\router.js
 * @Copyright John
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '@/views/login.vue'
import error from '@/components/error.vue'
import main from '@/views/main.vue'
import site from '@/views/site.vue'
import store from '@/store/index.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '',
    component: login,
    meta: { requiresAuth: false }
  },
  {
    path: '/login',
    name: 'login',
    component: login,
    meta: { requiresAuth: false }
  },
  {
    path: '/register',
    name: 'register',
    // 懒加载
    component: () => import('@/views/register.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/toResetPwd',
    name: 'toResetPwd',
    // 懒加载
    component: () => import('@/views/toResetPwd.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/resetPwd/:email/:token',
    name: 'resetPwd',
    // 懒加载
    component: () => import('@/views/resetPwd.vue'),
    props: true,
    meta: { requiresAuth: false }
  },
  {
    path: '/site',
    name: 'site',
    component: site,
    children: [
      {
        path: '/about',
        name: 'about',
        // 懒加载
        component: () => import('@/views/site/about.vue'),
        meta: { requiresAuth: false }
      },
      {
        path: '/help',
        name: 'help',
        // 懒加载
        component: () => import('@/views/site/help.vue'),
        meta: { requiresAuth: false }
      },
    ]
  },
  {
    path: '/main',
    name: 'main',
    component: main,
    meta: { requiresAuth: true },
    children: [
      {
        path: '', 
        redirect: 'welcome'
      },
      {
        path: 'welcome',   // 这里如果以"/"开头代表绝对路径，访问时匹配/welcome
        name: 'welcome', 
        component: () => import('@/views/welcome.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'dtgl-list',
        name: '我的定投',
        component: () => import('@/views/dtgl/list.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'dtgl-tjdt',
        name: '添加定投',
        component: () => import('@/views/dtgl/add.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'dtsy-list',
        name: '定投收益',
        component: () => import('@/views/dtsy/list.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'networth-list',
        name: '净值统计',
        component: () => import('@/views/data/networth-list.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'dtsy-statistic',
        name: '收益统计',
        component: () => import('@/views/dtsy/statistic.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'func-add-remind',
        name: '设置提醒',
        component: () => import('@/views/func/addRemind.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'func-profile',
        name: '个人中心',
        component: () => import('@/views/func/profile.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'func-reset-pwd',
        name: '重置密码',
        component: () => import('@/views/func/resetPwd.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'trans-list',
        name: '交易记录查询',
        component: () => import('@/views/trans/list.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'trans-add',
        name: '交易记录添加',
        component: () => import('@/views/trans/add.vue'),
        meta: { requiresAuth: true }
      }
    ]
  },
  {
    path: '*',
    name: '404',
    component: error
  }
]

const router = new VueRouter({
  mode: 'history', // history、hash
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // console.log(`${to.path}: 需要授权`)
    // 追加： 之所以访问不到，是因为原因登录后使用了location.href跳转会导致浏览器刷新，vuex中的数据就丢失了。同理，用户手动刷新页面也会导致vuex数据丢失，
    //    解决办法用插件将vuex持久化（参见@/store/index.js）
    // 直接访问store.state.hasLogin貌似不行，没有找到什么好的办法，如果有知道的请告知~ lijun_0372@163.com(已解决)
    // if(!localStorage.getItem('hasLogin')) {  
    if(!store.state.hasLogin) {  
      next({path: '/login'})
    } else {
      next();
    }
  } else {
    // console.log(`${to.path}: 不需要授权`)
    next()
  }
})

export default router
